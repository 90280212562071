/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <plan-action-editor-dialog
      v-if="activeItem != null"
      @save="savePlanaction($event)"
      :title="
        activeItem != null && activeItem.id == null
          ? 'Ajouter un plan action'
          : 'Editer un plan action'
      "
      ref="editor"
      :item="activeItem"
    />
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.4em" mode="indeterminate" />

      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des plans actions</h4>
        </div>
        <div class="btn-group">
          <a
            @click.prevent="addPlanaction()"
            v-if="$can('add', 'PlanAction')"
            href=""
            class="style_btn btn btn-warning"
            >Ajouter un plan action</a
          >
        </div>

        <div class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
      </div>

      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="this.planactions"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>
                <template #cell(nom)="{ item: planaction }">
                  <span class="text-uppercase">{{ planaction.libelle }}</span>
                </template>
                <template #cell(etatMiseEnOeuvre)="{ item: planaction }">
                  <PTag
                    class="text-uppercase badge badge-primary"
                    :severity="getStatusPlanaction(planaction.etatMiseEnOeuvre)"
                    v-if="planaction.etatMiseEnOeuvre"
                    >{{ planaction.etatMiseEnOeuvre }}
                  </PTag>
                </template>
                <template #cell(datePlanaction)="{ item: planaction }">
                  <span class="text-uppercase" v-if="planaction.datePlanaction"
                    >{{ planaction.datePlanaction | moment("DD-MM-YYYY") }}
                  </span>
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <a
                      class="btn btn-outline-success rounded-0 btn-sm me-1"
                      title="Modifier un plan action"
                      v-if="$can('edit', 'PlanAction')"
                      @click.stop="update(row.item)"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </a>
                    <a
                      class="btn btn-outline-danger rounded-0 btn-sm me-1"
                      title="Supprimer une action requise"
                      @click.stop="removePlanAction(row.item)"
                    >
                      <i class="bi bi-trash"></i>
                    </a>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ (mxPagination.page - 1) * mxPagination.rowsPerPage }} à
                {{ mxPagination.page * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import PlanActionEditorDialog from "../../../components/espace/entreprise/audit/editor/dialog/PlanActionEditorDialog.vue";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: ``,
    },
    constat: {
      type: Object,
      required: false,
    },
    actionRequise: {
      type: Object,
      required: false,
    },
  },
  components: {
    PlanActionEditorDialog,
    CAutoComplete,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      modal1: "",
      dateDeb: null,
      dateFin: null,
      showAdvancedFilter: false,
      displayDialog: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "table", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      parAeEnregistres: [],
      activeOffre: {},
      activeItem: {},
      planaction: [],

      exportActions: [
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                dataGetter: this.mxDataGetter,
                fetcherMethod: this.mxFetcherMethod,
                queryParams: params,
                fileName: "Liste des bénéficiaires Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
          },
        },
      ],
      searchFields: ["nom"],
      filterOptions: [
        {
          column: "nom",
          value: "",
          label: "Bénéficiaire",
          dropdown: false,
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          column: "phone",
          value: "",
          label: "Téléphone",
          dropdown: false,
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          column: "creePar",
          value: "",
          label: "AE (Nom & prénom)",
          dropdown: false,
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          column: "departement",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Département",
          dropdown: true,
          url: "/departements",
          visible: this.$can("filter.departement", "Azoli"),
        },
        {
          column: "commune",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Commune",
          dropdown: true,
          url: "/communes",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          column: "arrondissement",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/arrondissements",
          label: "Arrondissement",
          visible: this.$can("filter.arrondissement", "Azoli"),
        },
        {
          column: "ville",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/villes",
          label: "Village/Quartier de ville",
          visible: this.$can("filter.ville", "Azoli"),
        },
        {
          column: "status",
          key: "label",
          value: "",
          label: "Statut du dossier",
          multiple: true,
          valueKey: "value",
          items: [
            { value: "NOUVEAU", label: "Sans dossier" },
            { value: "EN_COURS", label: "En cours" },
            { value: "TERMINE", label: "Terminé" },
            { value: "VALIDE", label: "Validé" },
          ],
          visible: this.$can("filter.status", "Azoli"),
        },
        {
          column: "metierAppris",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier appris",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          column: "metierDesire",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier recherché",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeDemandeur: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    this.init();
  },
  watch: {},
  computed: {
    ...mapGetters({
      planactions: "planaction/planactions",
      audits: "audit/audits",
      romes: "rome/romes",
    }),

    tableFields() {
      return [
        "#",
        "index",
        // { key: "id", label: "N°" },
        { key: "responsable", label: "Responsable" },
        { key: "dateMiseEnOeuvre", label: "Date de mise en oeuvre" },
        { key: "echeance", label: "Date d'echéance" },
        { key: "etatMiseEnOeuvre", label: "Etat" },
        { key: "commentaire", label: "Commentaire" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  methods: {
    ...mapActions({
      fetchPlanactionsConstat: "planaction/fetchPlanactionsConstat",
      deletePlanaction: "planaction/deletePlanaction",
      fetchPlanactionsActionRequise: "planaction/fetchPlanactionsActionRequise",

      createOrUpdatePlanaction: "planaction/createOrUpdatePlanaction",
    }),
    async removePlanAction(item) {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment supprimer ce plan d'action?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.deletePlanaction(item).then((data) => {
            this.$toast.info("Action réquise supprimée avec succès!", {
              position: "top-right",
              duration: 10000,
            });
          });
        },
      });
    },
    init() {
      this.mxLoading = true;
      if (this.actionRequise != null) {
        this.fetchPlanactionsActionRequise(this.actionRequise.id)
          .then((data) => {
            this.mxLoading = false;
          })
          .catch((error) => {
            this.mxLoading = false;
          });
      } else {
        this.queryStatistics();
      }
    },
    validation() {},
    savePlanaction(payload) {
      payload = {
        ...payload,
        constat: this.constat,
        actionRequise: this.actionRequise,
      };

      this.createOrUpdatePlanaction(payload)
        .then(() => {
          this.$toast.success("Enregistré ", {
            position: "bottom-right",
            duration: 8000,
          });
        })
        .catch((err) => {
          this.$toast.error("Echec ", {
            position: "bottom-right",
            duration: 8000,
          });
        });
    },
    getStatusPlanaction(status) {
      let color = "primary";
      if (status == "FAIT") {
        color = "success";
      } else if (status == "ENCOURS") {
        color = "warning";
      }
      return color;
    },

    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "planaction",
        search: this.search,
        fetcherMethod: "planaction/fetchPlanactions",
        dataGetter: "planaction/planactions",
        paginationGetter: "planaction/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 20,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);
          return `dateDeb=${dateDeb}&dateFin=${dateFin}&${this.extraQueryArg}`;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    uploadFile(de) {
      console.log(de);
      this.$refs[`pond2`].click();
    },
    onProgress(e) {
      console.log(e);
    },
    addPlanaction() {
      this.activeItem = {};
      this.$refs.editor.show();
    },
    updatePlanaction(payload) {
      this.activeItem = { ...payload };
      this.$refs.editor.show();
    },
    update(planaction) {
      this.activeItem = { ...planaction };
      this.$refs.editor.show();
    },
  },
};
</script>

<style></style>
