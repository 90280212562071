<template>
  <div class="">
    <PDialog
      v-if="!inline"
      :header="title"
      :modal="true"
      :visible.sync="display"
      :containerStyle="{ width: $screen.breakpoint == 'sm' ? '100vw' : '60vw' }"
    >
      <PTabView :activeIndex.sync="activeTab">
        <PTabPanel header="Choisir un fichier">
          <FilePondUploader
            ref="fileUploader"
            class="col-12 md:col-12"
            @save="fileSaved"
          />
        </PTabPanel>
      </PTabView>
      <template #footer>
        <PButton
          label="Annuler"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton
          label="Valider"
          icon="pi pi-check"
          @click="save(signatureUrl)"
          autofocus
        />
      </template>
    </PDialog>
  </div>
</template>

<script>
import FilePondUploader from "./FilePondUploader.vue";

export default {
  props: {
    title: {
      type: String,
      default: "Uploadeur de fichier",
    },
    inline: {
      type: Boolean,
      require: true, //If false then will function as a dialog
    },
  },
  components: {
    FilePondUploader,
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      error: false,
      signatureUrl: null,
    };
  },
  created() {},
  methods: {
    resetContent() {
      this.signatureUrl = null;
    },
    show() {
      this.resetContent();
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    fileSaved(files) {
      console.log(files);
      if (files.length > 0) {
        this.signatureUrl = files[0];
        this.error = !(
          typeof this.signatureUrl == "string" || this.signatureUrl instanceof String
        );
      }
    },
    async save() {
      if (typeof this.signatureUrl == "string" || this.signatureUrl instanceof String) {
        this.$emit("save", this.signatureUrl);
        this.hide();
      } else {
        this.error = true;
      }
    },
  },
};
</script>

<style scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
