/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <PSidebar
      class="p-sidebar-lg p-sidebar-close"
      :dismissable="true"
      :visible.sync="displayDialog"
      position="right"
      containerStyle="{width: '70vw'}"
    >
      <constat-detail-viewer :constat="activeOffre" />
    </PSidebar>
    <constat-editor-dialog
      v-show="false"
      @save="saveConstat($event)"
      :title="
        activeItem != null && activeItem.constat == null
          ? 'Ajouter un constat'
          : 'Editer un constat'
      "
      ref="editor"
      :item="activeItem"
      :audits="audits"
      :entreprise="entreprise"
    />

    <TakePicture v-if="displayCamera" @save="fileSaved" @close="displayCamera = false" />
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.4em" mode="indeterminate" />

      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des constats</h4>
        </div>
        <div class="btn-group">
          <a @click.prevent="addConstat()" href="" class="style_btn btn btn-warning"
            >Ajouter un constat</a
          >
        </div>
        <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
      </div>

      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="this.constats"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>
                <template #cell(noteRisque)="{ item: constat }">
                  <span
                    class="text-uppercase"
                    :class="getcollorRisque(constat.noteRisque)"
                    >{{ constat.noteRisque }}</span
                  >
                  <PTag :value="noteCriticite"></PTag>
                </template>
                <template #cell(dateConstat)="{ item: constat }">
                  <span class="text-uppercase" v-if="constat.dateConstat"
                    >{{ constat.dateConstat | moment("DD-MM-YYYY") }}
                  </span>
                </template>
                <template #row-details="row" v-for="audit in audits">
                  <PTabView :activeIndex.sync="activeTab">
                    <PTabPanel header="Evaluation">
                      <CritereEvaluation
                        :constat="row.item"
                        :audit="audit"
                        @updateNote="updateNote($event)"
                      />
                    </PTabPanel>
                    <PTabPanel header="Photos">
                      <ConstatPhotoList :constat="row.item" />
                    </PTabPanel>
                    <!-- <PTabPanel header="Point(s) fort(s)">
                      <Atouts :constat="row.item" />
                    </PTabPanel> -->
                    <PTabPanel header="Actions requises">
                      <div class="col-sm-12">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <h5>Synthèse des points faibles (partie 4.2 du rapport)</h5>
                          <div class="form- mb-3">
                            <!-- <textarea
                              v-model="row.item.syntheseActionRequise"
                              type="text"
                              rows="10"
                              height="100"
                              class="form-control"
                              id="syntheseActionRequise"
                              name="syntheseActionRequise"
                              style="height: 20px"
                              placeholder="Synthèse action réquise"
                            />
                          -->
                            <PTextarea
                              class="col-12"
                              id="syntheseActionRequise"
                              v-model="activeItem.syntheseActionRequise"
                              rows="10"
                              @blur="saveExistingConstat(activeItem)"
                            />
                            <!-- <PEditor
                              v-model="activeItem.syntheseActionRequise"
                              editorStyle="height: 150px"
                              @text-change="saveExistingConstat(activeItem)"
                            /> -->

                            <!-- <label for="syntheseActionRequise"
                              >Synthèse des plans d'action</label
                            > -->
                            <span
                              v-for="(err, index) in errors"
                              :key="index"
                              class="text-danger"
                              >{{ err }}</span
                            >
                          </div>
                        </ValidationProvider>
                      </div>
                      <ActionRequiseList :constat="row.item" />
                    </PTabPanel>
                    <!-- <PTabPanel header="Plan d'actions">
                      <PlanAction :constat="row.item" />
                    </PTabPanel> -->

                    <PTabPanel header="Levée de criticité">
                      <LeverCritereEvaluation
                        :constat="row.item"
                        :audit="audit"
                        @updateNote="updateNote($event)"
                      />
                    </PTabPanel>
                  </PTabView>
                  <!-- <CandidatureList :extraQueryArg="`offreId=${row.item.id}`" /> -->
                </template>
                <template #cell(libelle)="{ item: constat }">
                  <div @mouseenter="" v-tooltip.top="constat.libelle">
                    {{ constat.libelle | truncate(100) }}
                  </div>
                </template>
                <template #cell(noteCriticite)="{ item: constat }">
                  <!-- <span class="text-uppercase" :class="constat.codeCouleurCritique">{{ constat.noteCriticite }}</span> -->
                  <PTag class="badge" :class="constat.codeCouleurCritique">
                    {{ constat.noteCriticite }}</PTag
                  >
                </template>
                <template #cell(noteRisque)="{ item: constat }">
                  <!-- <span class="text-uppercase" :class="constat.codeColeurRisque">{{ constat.noteRisque }}</span> -->
                  <PTag class="badge" :class="constat.codeColeurRisque">{{
                    constat.noteRisque
                  }}</PTag>
                </template>
                <template #cell(estEvaluer)="{ item: constat }">
                  <PTag
                    class="badge badge-primary"
                    :severity="getStatusConstat(constat.estEvaluer)"
                    v-if="constat.estEvaluer"
                  >
                    EVALUE
                  </PTag>
                  <PTag
                    class="badge badge-primary"
                    :severity="getStatusConstat(constat.estEvaluer)"
                    v-else
                  >
                    NON EVALUE
                  </PTag>
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      v-tooltip.top="'Voir détails/évaluation'"
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>

                    <a
                      class="btn btn-outline-success rounded-0 btn-sm me-1"
                      title="Modifier un constat"
                      @click.stop="update(row.item)"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </a>
                    <a
                      class="btn btn-outline-danger rounded-0 btn-sm me-1"
                      title="Supprimer le constat"
                      @click.stop="removeConstat(row.item)"
                    >
                      <i class="bi bi-trash"></i>
                    </a>

                    <!-- <a
                      class="btn btn-warning rounded btn-sm me-1"
                      v-tooltip.top="'Ajouter une photo'"
                      @click.stop="takePicture(row.item)"
                      v-if="row.item.urlPhoto == null"
                      ><i class="bi bi-camera"></i>
                    </a>
                    <a
                      v-else
                      class="btn btn-warning rounded btn-sm me-1"
                      v-tooltip.top="'Voir photo photo'"
                      @click.stop="picturePreview(row.item)"
                      ><i class="bi bi-card-image"></i
                    ></a> -->
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                <!-- Affichage de l’élément
                {{ (mxPagination.page - 1) * mxPagination.rowsPerPage }} à
                {{ mxPagination.page * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments -->
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import ConstatEditorDialog from "../../../components/espace/entreprise/audit/editor/dialog/ConstatEditorDialog.vue";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import CandidatureList from "./CandidatureList.vue";
import CritereEvaluation from "./CritereEvaluation.vue";
import LeverCritereEvaluation from "./LeverCritereEvaluation.vue";
import ActionRequiseList from "./ActionRequiseList.vue";
import PlanAction from "./PlanActionList.vue";
import Atouts from "./AtoutsList.vue";
import TakePicture from "../../../components/common/TakePicture.vue";
import ConstatPhotoList from "./ConstatPhotoList.vue";

import { VueEditor } from "vue2-editor";
import { FILE_UPLOAD_API_URL } from "../../../config/api-config";

import OffreDetailViewer from "../../portail/OffreDetailViewer.vue";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: ``,
    },
    entreprise: {
      type: Object,
      required: false,
    },
  },
  components: {
    ConstatEditorDialog,
    // DeStatistic,
    TakePicture,
    VueEditor,
    Atouts,
    PlanAction,
    ActionRequiseList,
    CritereEvaluation,
    OffreDetailViewer,
    CandidatureList,
    CAutoComplete,
    LeverCritereEvaluation,
    ConstatPhotoList,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      modal1: "",
      dateDeb: null,
      dateFin: null,
      showAdvancedFilter: false,
      displayDialog: false,
      displayCamera: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "table", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      parAeEnregistres: [],
      activeOffre: {},
      activeItem: {},
      constat: [],

      exportActions: [
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                dataGetter: this.mxDataGetter,
                fetcherMethod: this.mxFetcherMethod,
                queryParams: params,
                fileName: "Liste des bénéficiaires Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
          },
        },
      ],
      searchFields: ["nom"],
      searchFieldsOptions: [
        {
          label: "Bénéficiaire",
          value: "nom",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          label: "Téléphone",
          value: "phone",
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          label: "Whatsapp",
          value: "whatsapp",
          visible: this.$can("filter.whatsapp", "Azoli"),
        },
        {
          label: "Commune",
          value: "commune",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          label: "Département",
          value: "departement",
          visible: this.$can("filter.departement", "Azoli"),
        },
        {
          label: "AE (Nom & prénom)",
          value: "ae",
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          label: "Arrondissement",
          value: "arrondissement",
          visible: this.$can("filter.arrondissement", "Azoli"),
        },
        {
          label: "Village/Quartier de ville",
          value: "ville",
          visible: this.$can("filter.ville", "Azoli"),
        },
        {
          label: "Métier Appris",
          value: "metierAppris",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          label: "Métier Désiré",
          value: "metierDesire",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      filterOptions: [
        {
          column: "nom",
          value: "",
          label: "Bénéficiaire",
          dropdown: false,
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          column: "phone",
          value: "",
          label: "Téléphone",
          dropdown: false,
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          column: "creePar",
          value: "",
          label: "AE (Nom & prénom)",
          dropdown: false,
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          column: "departement",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Département",
          dropdown: true,
          url: "/departements",
          visible: this.$can("filter.departement", "Azoli"),
        },
        {
          column: "commune",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Commune",
          dropdown: true,
          url: "/communes",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          column: "arrondissement",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/arrondissements",
          label: "Arrondissement",
          visible: this.$can("filter.arrondissement", "Azoli"),
        },
        {
          column: "ville",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/villes",
          label: "Village/Quartier de ville",
          visible: this.$can("filter.ville", "Azoli"),
        },
        {
          column: "status",
          key: "label",
          value: "",
          label: "Statut du dossier",
          multiple: true,
          valueKey: "value",
          items: [
            { value: "NOUVEAU", label: "Sans dossier" },
            { value: "EN_COURS", label: "En cours" },
            { value: "TERMINE", label: "Terminé" },
            { value: "VALIDE", label: "Validé" },
          ],
          visible: this.$can("filter.status", "Azoli"),
        },
        {
          column: "metierAppris",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier appris",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          column: "metierDesire",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier recherché",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeDemandeur: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    // this.getEntrepriseConstat(this.entreprise.id);
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeDemandeur = {
          ...val,
          ...val.azoli,
        };
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
    // entreprise(){
    //   this.getEntrepriseConstat(entreprise.id);
    // }
  },
  computed: {
    ...mapGetters({
      constats: "constat/constats",
      audits: "audit/audits",
      pagination: "demandeur/pagination",
      romes: "rome/romes",
    }),
    auditActif() {
      return this.audits.length != 0 ? this.audits[0] : null;
    },
    getcollorRisque(payload) {
      switch (true) {
        case 0 <= payload && payload <= 300:
          // this.constat.codeColeurRisque = "vert"
          return "vert"; //vert
        case 300 < payload && payload <= 400:
          // this.constat.codeColeurRisque = "jaune"
          return "jaune"; //jaune
        case 400 < payload && payload < 500:
          // this.constat.codeColeurRisque = "orange"
          return "orange"; // orange
        case 500 <= payload && payload <= 1000:
          // this.constat.codeColeurRisque = "rouge"
          return "rouge"; // rouge
      }
    },

    tableFields() {
      return [
        "#",
        "index",
        // { key: "id", label: "N°" },
        { key: "domaineAnalyse.libelle", label: "Critère" },
        { key: "libelle", label: "Constat(s)" },
        { key: "noteCriticite", label: "Criticité" },
        { key: "noteRisque", label: "Risque" },
        { key: "estEvaluer", label: "Statut" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    fileApiUrl() {
      return FILE_UPLOAD_API_URL;
    },
  },
  methods: {
    ...mapActions({
      fetchConstats: "constat/fetchConstats",
      deleteConstat: "constat/deleteConstat",
      fetchAzoliStatistics: "statistic/fetchAzoliStatistics",
      fetchDemandeurs: "demandeur/fetchDemandeurs",
      createOrUpdateDemandeur: "demandeur/createOrUpdateDemandeur",
      deleteDemandeur: "demandeur/deleteDemandeur",
      downloadAzoli: "demandeur/downloadAzoli",
      validerDossier: "demandeur/validerDossier",
      getEntrepriseConstat: "constat/getEntrepriseConstat",

      createOrUpdateConstat: "constat/createOrUpdateConstat",
    }),
    fileSaved(fileUrl) {
      this.activeItem = {
        ...this.activeItem,
        urlPhoto: fileUrl,
      };
    },
    takePicture(constat) {
      this.activeItem = {
        ...constat,
      };
      this.displayCamera = true;
    },
    showDetail(row) {
      //   row.toggleDetails();
      this.mxToggleDetails(row, this.tableData);
      this.activeItem = { ...row.item };
    },
    updateNote(payload) {
      console.log(payload);
      this.blocked = true;
      this.createOrUpdateConstat(payload)
        .then(() => {
          this.blocked = false;
          this.$toast.success("Enregistré ", {
            position: "bottom-right",
            duration: 8000,
          });
        })
        .catch((err) => {
          this.$toast.error("Echec d'ajout d'une note ", {
            position: "bottom-right",
            duration: 8000,
          });
        });
    },
    saveExistingConstat(constat) {
      this.createOrUpdateConstat({ ...this.activeItem })
        .then(() => {
          this.$toast.success("Enregistré ", {
            position: "bottom-right",
            duration: 8000,
          });
        })
        .catch((err) => {
          this.$toast.error("Echec ", {
            position: "bottom-right",
            duration: 8000,
          });
        });
    },
    saveConstat(payload) {
      payload = {
        ...payload,
        entreprise: this.entreprise,
      };
      console.log(payload);

      this.createOrUpdateConstat(payload)
        .then(() => {
          this.$toast.success("Enregistré ", {
            position: "bottom-right",
            duration: 8000,
          });
        })
        .catch((err) => {
          this.$toast.error("Echec ", {
            position: "bottom-right",
            duration: 8000,
          });
        });
    },
    getcollorCriticite() {
      switch (true) {
        case 0 <= this.noteCriticite && this.noteCriticite <= 30:
          this.constat.codeCouleurCritique = "vert";
          return "vert"; //vert
        case 30 < this.noteCriticite && this.noteCriticite <= 40:
          this.constat.codeCouleurCritique = "jaune";
          return "jaune"; //jaune
        case 40 < this.noteCriticite && this.noteCriticite < 50:
          this.constat.codeCouleurCritique = "orange";
          return "orange"; // orange
        case 50 <= this.noteCriticite && this.noteCriticite <= 100:
          this.constat.codeCouleurCritique = "rouge";
          return "rouge"; // rouge
      }
    },
    getcollorRisque1(payload) {
      switch (true) {
        case 0 <= payload && payload <= 300:
          // this.constat.codeColeurRisque = "vert"
          return "vert"; //vert
        case 300 < payload && payload <= 400:
          // this.constat.codeColeurRisque = "jaune"
          return "jaune"; //jaune
        case 400 < payload && payload < 500:
          // this.constat.codeColeurRisque = "orange"
          return "orange"; // orange
        case 500 <= payload && payload <= 1000:
          // this.constat.codeColeurRisque = "rouge"
          return "rouge"; // rouge
      }
    },
    getStatusConstat(status) {
      let color = "success";
      if (status == true) {
        color = "success";
      } else {
        color = "danger";
      }
      return color;
    },

    getStatusOffreColor(status) {
      let color = "EN_ATTENTE";
      if (status == "VALIDE") {
        color = "primary";
      } else if (status == "PUBLIE") {
        color = "success";
      } else if (status == "ANNULE") {
        color = "danger";
      } else if (status == "EN_ATTENTE") {
        color = "secondary";
      }
      return color;
    },
    priseEnCharge(de) {
      this.$router.push({
        name: "espace.common.de.followup",
        params: { userRole: this.$route.params.userRole, demandeurId: de.id },
      });
    },
    setDatePub(oneOffre) {
      this.activeOffre = { ...oneOffre };
    },
    annluerOffre(oneOffre) {
      this.$swal
        .fire({
          title: "Confirmation",
          text: "Confirmez-vous l'annulation de cette constat ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Non",
          confirmButtonText: "Oui",
        })
        .then((result) => {
          if (result.isConfirmed) {
            oneOffre.status = "ANNULE";
            this.validationOffre(oneOffre).then(() => {
              this.$toast.error("constat annulée ", {
                position: "bottom-right",
                duration: 8000,
              });
            });
          }
        });
    },

    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "constat",
        search: this.search,
        fetcherMethod: "constat/fetchConstats",
        dataGetter: this.entreprise.constats,
        paginationGetter: "constat/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 20,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);
          return `dateDeb=${dateDeb}&dateFin=${dateFin}&${this.extraQueryArg}`;
        },
        searchFields: this.searchFields,
      });
    },

    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    uploadFile(de) {
      console.log(de);
      this.$refs[`pond2`].click();
    },
    onProgress(e) {
      console.log(e);
    },
    addConstat() {
      // this.constat.push(emp)
      this.activeItem = {
        audit: this.auditActif,
      };
      this.$refs.editor.show();
    },
    updateConstat(payload) {
      // this.constat.push(emp)
      this.activeItem = { ...payload };
      this.$refs.editor.show();
    },
    // downloadFiche(de) {
    //   // FileService.downloadFile(`demandeurs${de.id}/pdf`, `fichier_${de.id}.pdf`).then(()=>{})
    //   // this.downloadAzoli(de).then(()=>{})
    //   this.fetchDossier({
    //     demandeurId: de.id,
    //   }).then(() => {
    //     console.log(this.$refs.html2Pdf);
    //     this.$refs.html2Pdf.generatePdf();
    //   });
    // },
    // editDemandeur(demandeur) {
    //   console.log(demandeur);
    //   this.activeDemandeur = { ...demandeur };
    //   this.$router.push({ name: "espace.conseiller.de.edit" });
    // },
    // showDemandeur(demandeur) {
    //   console.log(demandeur);
    //   this.activeDemandeur = { ...demandeur };
    //   this.$router.push({
    //     name: "espace.conseiller.de.detail",
    //     params: { demandeurId: demandeur.id },
    //   });
    // },
    update(constat) {
      this.activeItem = { ...constat };
      this.$refs.editor.show();
    },

    async removeConstat(constat) {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment supprimer le constat ${constat.libelle} ?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.deleteConstat(constat).then((data) => {
            this.$toast.info("Contast supprimé avec succès!", {
              position: "top-right",
              duration: 10000,
            });
          });
        },
      });
    },
  },
};
</script>

<style>
.vert {
  background: #00561b;
  color: white;
}

.jaune {
  background: #ffff00;
  color: black;
}

.orange {
  background: #faa401;
}

.rouge {
  background: #ff0000;
  color: white;
}
</style>
