<template>
  <modal :title="title" ref="modal" @save="save" :hide-footer="true">
    <template #default="{}">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col-md-12 col-md-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <PDropdown
                    class="col-12"
                    v-model="editableItem.typeAtout"
                    :options="typeAtouts"
                    optionLabel="name"
                    placeholder="Type point fort/faible"
                    optionValue="typeAtout"
                  />
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                  <!-- <label for="typeAction">Type d'actions</label> -->
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <textarea
                    :value="editableItem.libelle"
                    @input="handleInput"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="libelle"
                    style="height: 200px"
                    rows="10"
                    name="libelle"
                    placeholder="Libelle"
                  />
                  <label for="libelle">Libelle</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>

          <div class="d-grid gap-2">
            <button
              @click.prevent="save"
              type="submit"
              class="style_btn btn btn-success btn-lg"
            >
              Enrégistrer
            </button>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
// import CSelect from '@/components/CSelect.vue'
import MetierSelect from "../../../../../common/MetierSelect.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      require: true,
    },
    hideNiveau: {
      type: Boolean,
      default: () => false,
    },
    audit: {
      type: Object,
      default: () => {},
    },
    entreprise: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [],
  components: {
    Modal,
    //  CSelect,
    MetierSelect,
  },
  data() {
    return {
      editableItem: {},
      typeAtouts: [
        { typeAtout: "POINT_FORT", name: "POINT FORT" },
        // { typeAtout: "POINT_FAIBLE", name: " POINT FAIBLE" },
      ],
    };
  },
  created() {},
  watch: {
    item() {
      this.editableItem = { ...this.item };
    },
  },
  computed: {
    ...mapGetters({
      // actionrequises:"actionrequise/actionrequises"
    }),

    total() {
      return this.editableItem.homme + this.editableItemfemme;
    },
  },
  methods: {
    ...mapActions({}),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    show() {
      this.$refs.modal.show();
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("save", this.editableItem);
      this.close();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
